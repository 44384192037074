import { createContext, useContext, useMemo, useRef } from 'react'
import Notification from '../components/Notification'
const NotificationContext = createContext({
  success: () => undefined,
  error: () => undefined,
  alert: () => undefined
})

NotificationContext.displayName = 'NotificationContext'

export function NotificationContextProvider(props) {
  const modalRef = useRef()
  const notificationRef = useRef()

  const value = useMemo(
    () => ({
      success: (message) => {
        notificationRef.current.alertWithType('success', message)
      },
      error: (message) => {
        notificationRef.current.alertWithType('error', message)
      },
      alert: modalRef.current?.alert
    }),
    []
  )

  return (
    <>
      <NotificationContext.Provider value={value} {...props} />
      <Notification ref={notificationRef} />
    </>
  )
}

export function useNotificationContext() {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error('useNotificationContext must be used within a AppProvider')
  }

  return context
}
