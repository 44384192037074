import { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Button from './Button'
import Modal from './Modal'
import { API } from 'aws-amplify'
import { partnerControl } from '../api/queries'
import { useNotificationContext } from '../context/notification-context'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Keine valide E-Mail-Adresse')
    .required('E-Mail-Adresse erforderlich')
})

const CreateCompanyModal = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { success } = useNotificationContext()

  const createCompany = async (email) => {
    try {
      setIsLoading(true)

      await API.graphql({
        query: partnerControl,
        variables: {
          action: 'createTrialPartner',
          email: email.toLowerCase()
        }
      })

      setIsLoading(false)
      success('Kunde erfolgreich angelegt')
    } catch (error) {
      console.error(error) // eslint-disable-line
    } finally {
      setOpen(false)
    }
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={async (values) => {
          createCompany(values.email)
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className='text-center'>
              <span className='text-base font-semibold leading-6 text-gray-900'>
                Neuen Kunden hinzufügen
              </span>
              <div className='mt-2'>
                <p className='text-sm text-gray-500'>
                  Als nächstes gebe bitte die E-Mail-Adresse des Kunden ein ...
                </p>
              </div>
            </div>
            <div className='mt-4'>
              <label htmlFor='email' className='sr-only'>
                Email
              </label>
              <Field
                type='email'
                name='email'
                id='email'
                className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                placeholder='new@customer.com'
              />
              {errors.email && touched.email ? (
                <div className='text-xs text-red-600 mt-1'>{errors.email}</div>
              ) : null}
            </div>
            <div className='mt-5 sm:mt-6'>
              <Button.PrimaryLG
                text={isLoading ? 'lädt ...' : 'Jetzt einladen'}
                fullWidth
                isLoading={isSubmitting}
                type='submit'
                disabled={!touched.email || errors.email || isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateCompanyModal
