export const PARTNER = `{
  id
  name
  display_name
  authorized_create
  authorized_read
  authorized_update
  career_site_url
  last_activity
  planned_new_hires_per_year
  app_settings {
    components
    challenges
    report_settings
    usedAlgorithm
    hello_text
    custom_data_policy
    notifications {
      webhook
      mail
    }
    assessment_expires_after_days
  }
  logo
  createdAt
  updatedAt
  __typename
}`
