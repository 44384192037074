export const FORM = `{
  id
  data
  partner_id
  status
  form_source
  ref_link
  lastUpdatedBy
  createdAt
  updatedAt
  __typename
}`
