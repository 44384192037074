import {
  UserGroupIcon,
  // PuzzlePieceIcon,
  CpuChipIcon,
  AcademicCapIcon,
  EyeIcon,
  ArrowPathRoundedSquareIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { classNames } from '../helper'

const actions = [
  {
    title: 'Kunden',
    description:
      'Verwalte Kunden, hinterlege oder aktualisiere eine Zahlung u.v.m',
    to: 'customers',
    icon: UserGroupIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50'
  },
  {
    title: 'Leads',
    description: 'Überblick über die aktuellen Leads aus dem Trial',
    to: 'leads',
    icon: AcademicCapIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50'
  },
  {
    title: 'Integrationen',
    description: 'Ein Überblick über die Integrationen unserer Partner.',
    to: 'integrations',
    icon: ArrowPathRoundedSquareIcon,
    iconForeground: 'text-fuchsia-700',
    iconBackground: 'bg-fuchsia-50'
  },
  {
    title: 'Inspect',
    description: 'Überprüfe Assessments, Accounts etc.',
    to: 'inspect',
    icon: EyeIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50'
  },
  // {
  //   title: "Talente",
  //   description:
  //     "Verwalte Talente und erhalte eine Übersicht über deren aktuellen Assessments",
  //   href: "#",
  //   icon: IdentificationIcon,
  //   iconForeground: "text-purple-700",
  //   iconBackground: "bg-purple-50",
  // },
  // {
  //   title: "Challenges",
  //   description:
  //     "Erhalte Statistiken zu abgeschlossenen Challenges, Feedback und mehr",
  //   href: "#",
  //   icon: PuzzlePieceIcon,
  //   iconForeground: "text-sky-700",
  //   iconBackground: "bg-sky-50",
  // },
  {
    title: 'System',
    description:
      'Überarbeite Dimensions, Ankündigungen, Challenges, Mail-Templates und mehr hier',
    to: 'system',
    icon: CpuChipIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50'
  }
  // {
  //   title: "Schedule a one-on-one",
  //   href: "#",
  //   icon: UsersIcon,
  //   iconForeground: "text-sky-700",
  //   iconBackground: "bg-sky-50",
  // },
  // {
  //   title: "Payroll",
  //   href: "#",
  //   icon: BanknotesIcon,
  //   iconForeground: "text-yellow-700",
  //   iconBackground: "bg-yellow-50",
  // },
  // {
  //   title: "Submit an expense",
  //   href: "#",
  //   icon: ReceiptRefundIcon,
  //   iconForeground: "text-rose-700",
  //   iconBackground: "bg-rose-50",
  // },
  // {
  //   title: "Training",
  //   href: "#",
  //   icon: AcademicCapIcon,
  //   iconForeground: "text-indigo-700",
  //   iconBackground: "bg-indigo-50",
  // },
]

const Overview = () => {
  return (
    <div>
      <div className='divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0'>
        {actions.map((action, actionIdx) => (
          <Link
            to={action.to}
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white'
                )}
              >
                <action.icon className='h-6 w-6' aria-hidden='true' />
              </span>
            </div>
            <div className='mt-8'>
              <h3 className='text-base font-semibold leading-6 text-gray-900'>
                <span className='focus:outline-none'>
                  {/* Extend touch target to entire panel */}
                  <span className='absolute inset-0' aria-hidden='true' />
                  {action.title}
                </span>
              </h3>
              <p className='mt-2 text-sm text-gray-500'>{action.description}</p>
            </div>
            <span
              className='pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400'
              aria-hidden='true'
            >
              <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 24 24'>
                <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
              </svg>
            </span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Overview
