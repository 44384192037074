export const PRODUCTS = {
  ATTRACT: {
    title: 'Aivy® Attract',
    description: 'Orientierungsmodul für Karrierewebseiten'
  },
  //   TALENTPOOL: Symbol("TALENTPOOL"),
  SMARTPREDICT: {
    title: 'Aivy® Smart-Predict',
    description:
      'Nutze die intelligente KI von Aivy und optimiere deine vorhandenen Stellenprofile für noch bessere Matchings!'
  },
  //   FAKINGMODULE: Symbol("FAKINGMODULE"),
  //   INTERVIEWPAPER: Symbol("INTERVIEWPAPER"),
  BROWSERPLUGIN: {
    title: 'Aivy® Browser-Plugin',
    description:
      'Nutze das Browser-Plugin von Aivy, um einfach und nahtlos in den vorhandenen Prozessen eingebunden zu werden.'
  },
  // PUBLICINVITATIONLINK: {
  //   title: "Aivy® Öffentlicher Einladungslink",
  //   description:
  //     "Mit dem öffentlichen Einladungslink kannst du deine Talente über den erstellten Link zu dem Assessment deiner Stelle einladen.",
  // },
  REDIRECTTALENTURL: {
    title: 'Aivy® Weiterleitungs-URL',
    description:
      'Leite deine Talente zu einer URL deiner Wahl weiter, nachdem sie das Assessment abschließen.'
  },
  CROSSCOMPARISON: {
    title: 'Aivy® Kreuz-Matchings',
    description:
      'Mit Kreuz-Matchings lassen sich mehrere Talente auswählen, die sich gleichzeitig auf verschiedene Stellenprofile matchen lassen.'
  },
  INTEGRATIONS: {
    title: 'Aivy® Integrations',
    description:
      'Ermöglicht Integrationen zu Workday, Softgarden, WebHooks, Slack, Make, Zapier und mehr'
  },
  AUTOMATIONS: {
    title: 'Aivy® Automationen',
    description:
      'Durch das automatisierte Löschen von Talenten wird die Einhaltung von Datenschutzrichtlinien vereinfacht.'
  }
}
