import { Fragment, useState } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { classNames } from '../helper'

const Table = ({ data, columns }) => {
  const [filtering, setFiltering] = useState('')
  const [sorting, setSorting] = useState([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      globalFilter: filtering
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering
  })

  return (
    <Fragment>
      <div className='w-60 my-2'>
        <label htmlFor='table_search' className='sr-only'>
          Suche
        </label>
        <div className='relative mt-1 rounded-md shadow-sm'>
          <div
            className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'
            aria-hidden='true'
          >
            <MagnifyingGlassIcon
              className='h-4 w-4 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='text'
            name='table_search'
            id='table_search'
            value={filtering}
            onChange={(e) => setFiltering(e.target.value)}
            className='block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder='Suche'
          />
        </div>
      </div>

      <table className='min-w-full divide-y divide-gray-300'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={
                    header.index === 0
                      ? 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer whitespace-nowrap'
                      : 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer whitespace-nowrap'
                  }
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <span className='ml-2'>🔼</span>,
                        desc: <span className='ml-2'>🔽</span>
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {table.getRowModel().rows.map((row) => (
            <tr className='group' key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <td
                  onClick={
                    cell.column.columnDef.onClick
                      ? () => cell.column.columnDef.onClick(cell)
                      : undefined
                  }
                  className={classNames(
                    cell.column.columnDef.onClick &&
                      'cursor-pointer group-hover:bg-gray-200',
                    index === 0
                      ? 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'
                      : 'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                  )}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default Table
