import { useState, useEffect } from 'react'
import { API, Auth, graphqlOperation } from 'aws-amplify'

import * as queries from '../api/queries'
import { query } from '../api'

export const useFindAll = () => {
  const [spaceID, setSpaceID] = useState()
  const [email, setEmail] = useState()
  const [partnerID, setPartnerID] = useState()
  const [careerID, setCareerID] = useState()
  const [sub, setSub] = useState()

  const [space, setSpace] = useState()
  const [partner, setPartner] = useState()
  const [user, setUser] = useState()
  const [answers, setAnswers] = useState()
  const [person, setPerson] = useState()
  const [career, setCareer] = useState()
  const [cognitoUser, setCognitoUser] = useState()
  const [spaces, setSpaces] = useState()
  const [payments, setPayments] = useState()
  const [employees, setEmployees] = useState()
  const [careers, setCareers] = useState()

  useEffect(() => {
    checkOpenRelations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space, spaces, career, partner, user, cognitoUser])

  function checkOpenRelations() {
    if (!space) {
      setSpaceByID(user?.active_space_id)
    }

    if (!spaces) {
      setSpacesByUserID(user?.id || space?.owner || person?.owner)
    }

    if (!cognitoUser) {
      setCognitoUserByID(spaceID || email)
    }

    if (!answers) {
      setAnswersWithAivyOutput(
        space?.owner || user?.owner || cognitoUser?.Username
      )
    }

    if (!career) {
      setCareerByID(space?.career_id || partner?.career_id)
    }

    if (!partner) {
      setPartnerByID(
        space?.partner_id || career?.partner_id || user?.employee_at_partner
      )
    }

    if (!user) {
      setUserByID(space?.owner || cognitoUser?.Username)
    }

    if (!person) {
      setPersonByID(space?.id || user?.person?.id)
    }

    if (!payments) {
      setPaymentByPartnerID(partner?.id)
    }

    if (!employees) {
      setEmployeesByPartnerID(partner?.id)
    }

    if (!careers) {
      setCareersByPartnerID(partner?.id)
    }
  }

  function clearAll() {
    setSpace(null)
    setUser(null)
    setPartner(null)
    setCognitoUser(null)
    setAnswers(null)
    setEmail(null)
    setSpaceID(null)
    setPerson(null)
    setCareer(null)
    setSpaces(null)
    setPayments(null)
    setEmployees(null)
    setCareers(null)
  }

  const setCognitoUserByID = async (user_id) => {
    if (!user_id) return

    const apiName = 'AdminQueries'
    const path = '/getUser'
    const myInit = {
      queryStringParameters: {
        username: user_id
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    }
    try {
      setCognitoUser('fetching')
      const res = await API.get(apiName, path, myInit)
      setCognitoUser(res)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setCognitoUser('error')
    }
  }

  const setSpaceByID = async (id) => {
    if (!id) return

    try {
      setSpace('fetching')
      const res = await API.graphql(graphqlOperation(queries.getSpace, { id }))
      setSpace(res.data.getSpace)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setSpace('error')
    }
  }

  const setAnswersWithAivyOutput = async (user_id) => {
    if (!user_id) return

    try {
      setAnswers('fetching')
      const res = await API.graphql(
        graphqlOperation(queries.answersByOwner, { owner: user_id })
      )
      setAnswers(res.data.answersByOwner.items)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setAnswers('error')
    }
  }

  const setSpacesByUserID = async (owner) => {
    if (!owner) return

    try {
      setSpaces('fetching')
      const res = await API.graphql(
        graphqlOperation(queries.spacesByOwner, { owner })
      )
      setSpaces(res.data.spacesByOwner.items)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setSpaces('error')
    }
  }

  const setCareersByPartnerID = async (id) => {
    if (!id) return

    try {
      setCareers('fetching')
      const res = await API.graphql(
        graphqlOperation(queries.careerByPartnerByDate, { partner_id: id })
      )
      setCareers(res.data.careerByPartnerByDate.items)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setCareers('error')
    }
  }

  const setEmployeesByPartnerID = async (partner_id) => {
    if (!partner_id) return

    try {
      setEmployees('fetching')

      const response = await query({
        query: 'spacesByPartnerIdByUpdatedAt',
        variables: {
          partner_id,
          filter: {
            context: { eq: 'EMPLOYEE' }
          }
        }
      })

      setEmployees(response)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setEmployees('error')
    }
  }

  const setPaymentByPartnerID = async (id) => {
    if (!id) return

    try {
      setPayments('fetching')
      const res = await API.graphql(
        graphqlOperation(queries.paymentByPartnerIdByCreatedAt, {
          partner_id: id
        })
      )
      setPayments(res.data.paymentByPartnerIdByCreatedAt.items)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setPayments('error')
    }
  }

  const setCareerByID = async (id) => {
    if (!id) return

    try {
      setCareer('fetching')
      const res = await API.graphql(graphqlOperation(queries.getCareer, { id }))
      setCareer(res.data.getCareer)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setCareer('error')
    }
  }

  const setPartnerByID = async (id) => {
    if (!id) return

    try {
      setPartner('fetching')
      const res = await API.graphql(
        graphqlOperation(queries.getPartner, { id })
      )
      setPartner(res.data.getPartner)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setPartner('error')
    }
  }

  const setUserByID = async (id) => {
    if (!id) return

    try {
      setUser('fetching')
      const res = await API.graphql(graphqlOperation(queries.getUser, { id }))
      setUser(res.data.getUser)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setUser('error')
    }
  }

  const setPersonByID = async (id) => {
    if (!id) return

    try {
      setPerson('fetching')
      const res = await API.graphql(graphqlOperation(queries.getPerson, { id }))
      setPerson(res.data.getPerson)
    } catch (err) {
      console.error(err) // eslint-disable-line
      setPerson('error')
    }
  }

  return {
    options: [
      {
        id: '01',
        field: 'space_id',
        label: 'Space ID',
        placeholder: 'Space ID eingeben',
        onChange: (event) => setSpaceID(event.target.value),
        onSubmit: () => setSpaceByID(spaceID)
      },
      {
        id: '02',
        field: 'email_',
        label: 'E-Mail-Adresse',
        placeholder: 'E-Mail-Adresse eingeben',
        onChange: (event) => setEmail(event.target.value),
        onSubmit: () => setCognitoUserByID(email)
      },
      {
        id: '03',
        field: 'career_id',
        label: 'Career ID',
        placeholder: 'Career ID eingeben',
        onChange: (event) => setCareerID(event.target.value),
        onSubmit: () => setCareerByID(careerID)
      },
      {
        id: '04',
        field: 'partner_id',
        label: 'Partner ID',
        placeholder: 'Partner ID eingeben',
        onChange: (event) => setPartnerID(event.target.value),
        onSubmit: () => setPartnerByID(partnerID)
      },
      {
        id: '05',
        field: 'username',
        label: 'Owner / Sub',
        placeholder: 'Owner / Sub eingeben',
        onChange: (event) => setSub(event.target.value),
        onSubmit: () => setCognitoUserByID(sub)
      }
    ],
    data: {
      space,
      spaces,
      career,
      partner,
      user,
      answers,
      person,
      cognitoUser,
      payments,
      employees,
      careers
    },
    clearAll
  }
}
