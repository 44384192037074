import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HomeIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid'
import { format, formatDistance } from 'date-fns'
import { de } from 'date-fns/locale'
import initials from 'initials'
import { BeatLoader } from 'react-spinners'

import { query, mutation } from '../api'
import Loader from '../components/Loader'
import { formatToCurrency, uuidv4 } from '../helper'

const Customer = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isCreatingPayment, setIsCreatingPayment] = useState(false)

  const {
    // status,
    data: customer,
    isLoading
  } = useQuery({
    queryKey: ['customer', id],
    queryFn: () => query({ query: 'getPartner', variables: { id } })
  })

  const { data: teammember, isLoading: isLoadingTeammember } = useQuery({
    queryKey: ['customer-teammember', id],
    queryFn: () => {
      return query({
        query: 'spacesByPartnerIdByUpdatedAt',
        variables: {
          partner_id: id,
          filter: {
            context: { eq: 'EMPLOYEE' }
          }
        }
      })
    }
  })

  const {
    data: payments,
    isLoading: isLoadingPayment,
    refetch: refetchPayments
  } = useQuery({
    queryKey: ['payment', id],
    queryFn: () =>
      query({
        query: 'paymentByPartnerIdByCreatedAt',
        variables: { partner_id: id }
      })
  })

  const customerName = customer?.display_name || customer?.name

  const pages = [
    { name: 'Kunden', to: '/customers', current: false },
    { name: customerName, to: '#', current: true }
  ]

  const createPayment = async () => {
    setIsCreatingPayment(true)

    const input = {
      id: uuidv4(),
      partner_id: id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      authorized_read: ['__read' + id],
      is_trial: false,
      products: [],
      price: 0,
      seat_count: 0,
      spaces_count: 0,
      career_count: 0,
      expires_at: new Date().toISOString(),
      start_at: new Date().toISOString()
    }

    await mutation({ mutation: 'createPayment', input })
    const { data } = await refetchPayments()

    const created_payment = data.find(({ id }) => id === input.id)
    setIsCreatingPayment(false)
    navigate(`/customer/payment/${created_payment.id}`)
  }

  const challenges = JSON.parse(customer?.app_settings?.challenges || '[]')

  if (isLoading || isLoadingTeammember || isLoadingPayment) {
    return <Loader loadingText={'Kunde wird geladen ...'} />
  }

  return (
    <div>
      <nav className='flex mb-8' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to={'/'} className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <svg
                  className='h-5 w-5 flex-shrink-0 text-gray-300'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                <Link
                  to={page.to}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <header className='mb-8'>
        <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
          {customer.display_name || customer.name}
        </h1>
      </header>
      <div className='mx-auto grid max-w-3xl grid-cols-1 lg:max-w-7xl'>
        <section aria-labelledby='applicant-information-title'>
          <div className='bg-white shadow sm:rounded-lg '>
            <div className='px-4 py-5 sm:px-6 flex flex-row justify-between'>
              <div>
                <h2
                  id='applicant-information-title'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Kundeninformation
                </h2>
                <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                  Informationen, Payment, Teams etc.
                </p>
              </div>
              {customer.logo && (
                <img
                  alt='customer logo'
                  src={customer.logo}
                  className='h-12 w-28 object-contain '
                />
              )}
            </div>
            <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
              <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                <div className='sm:col-span-1'>
                  <dt className='text-sm font-medium text-gray-500'>ID</dt>
                  <dd className='mt-1 text-sm text-gray-900'>{customer.id}</dd>
                </div>
                <div className='sm:col-span-1'>
                  <dt className='text-sm font-medium text-gray-500'>Name</dt>
                  <dd className='mt-1 text-sm text-gray-900'>
                    {customer.name}
                  </dd>
                </div>
                <div className='sm:col-span-1'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Karriere Webseite URL
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900'>
                    <a
                      className='text-indigo-600 hover:font-semibold'
                      href={customer.career_site_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {customer.career_site_url || '-'}
                    </a>
                  </dd>
                </div>
                <div className='sm:col-span-1'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Challenges im Partner festgelegt:
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900'>
                    {challenges.length === 0 && '-'}
                    <div className='flex flex-row gap-2 flex-wrap'>
                      {challenges.map((c, index) => (
                        <span
                          key={index}
                          className='inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700'
                        >
                          {c.exam_id}
                        </span>
                      ))}
                    </div>
                  </dd>
                </div>
                <div className='sm:col-span-1'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Erstellt:
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900'>
                    {format(new Date(customer.createdAt), 'dd.MM.yyyy')}
                  </dd>
                </div>
                <div className='sm:col-span-1'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Zuletzt geändert:
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900'>
                    {format(new Date(customer.updatedAt), 'dd.MM.yyyy')}
                  </dd>
                </div>
                {/* <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
                    incididunt cillum culpa consequat. Excepteur qui ipsum
                    aliquip consequat sint. Sit id mollit nulla mollit nostrud
                    in ea officia proident. Irure nostrud pariatur mollit ad
                    adipisicing reprehenderit deserunt qui eu.
                  </dd>
                </div> */}
                {/* <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    Attachments
                  </dt>
                </div> */}
              </dl>
            </div>
          </div>
        </section>
      </div>
      <section className='mt-4' aria-labelledby='teammember'>
        <div className='bg-white shadow sm:rounded-lg '>
          <div className='px-4 py-5 sm:px-6 flex flex-row justify-between'>
            <div>
              <h2
                id='teammember'
                className='text-lg font-medium leading-6 text-gray-900'
              >
                Teammitglieder
              </h2>
            </div>
          </div>
          <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
            <ul className='divide-y divide-gray-100'>
              {teammember.map((tm) => {
                const person = tm.person || {}
                const firstname =
                  person.firstname || person.invitation_firstname
                const lastname = person.lastname || person.invitation_lastname
                const email = person.email || person.invitation_email

                return (
                  <li key={tm.id} className='flex gap-x-4 py-5'>
                    <span className='inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500'>
                      <span className='font-medium leading-none text-white'>
                        {initials(`${firstname} ${lastname}`)}
                      </span>
                    </span>

                    <div className='min-w-0'>
                      <p className='text-sm font-semibold leading-6 text-gray-900'>
                        {`${firstname} ${lastname}`}
                      </p>
                      <p className='mt-1 truncate text-xs leading-5 text-gray-500'>
                        {`${email || tm.id}`}
                      </p>
                      {tm.last_seen && (
                        <p className='mt-1 text-xs text-gray-500'>
                          {`Zuletzt online: ${formatDistance(
                            new Date(tm.last_seen),
                            new Date(),
                            { locale: de }
                          )}`}
                        </p>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
      <section className='mt-4' aria-labelledby='payment'>
        <div className='bg-white shadow sm:rounded-lg '>
          <div className='px-4 py-5 sm:px-6 flex flex-row justify-between'>
            <div className='flex flex-row justify-between items-center w-full'>
              <h2
                id='payment'
                className='text-lg font-medium leading-6 text-gray-900'
              >
                Hinterlegte Zahlungen
              </h2>
              {payments.length > 0 && (
                <>
                  {isCreatingPayment ? (
                    <div className='py-1'>
                      <BeatLoader
                        color='#67e8f9'
                        speedMultiplier={0.5}
                        size={16}
                      />
                    </div>
                  ) : (
                    <button
                      onClick={createPayment}
                      type='button'
                      className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    >
                      <PlusIcon
                        className='-ml-0.5 mr-1.5 h-5 w-5'
                        aria-hidden='true'
                      />
                      Neue Zahlung
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
            <ul className='divide-y divide-gray-100'>
              {payments.length === 0 && (
                <div className='text-center'>
                  <svg
                    className='mx-auto h-12 w-12 text-gray-400'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      vectorEffect='non-scaling-stroke'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                    />
                  </svg>
                  <h3 className='mt-2 text-sm font-semibold text-gray-900'>
                    Keine Zahlung hinterlegt
                  </h3>
                  <p className='mt-1 text-sm text-gray-500'>
                    Für diesen Kunden wurde keine Zahlung hinterlegt.
                  </p>
                  <div className='mt-6'>
                    {isCreatingPayment ? (
                      <div className='py-1'>
                        <BeatLoader
                          color='#67e8f9'
                          speedMultiplier={0.5}
                          size={16}
                        />
                      </div>
                    ) : (
                      <button
                        onClick={createPayment}
                        type='button'
                        className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      >
                        <PlusIcon
                          className='-ml-0.5 mr-1.5 h-5 w-5'
                          aria-hidden='true'
                        />
                        Neue Zahlung
                      </button>
                    )}
                  </div>
                </div>
              )}
              {payments.map((payment) => (
                <Link
                  to={`/customer/payment/${payment.id}`}
                  key={payment.id}
                  state={{ customerName, customerId: id }}
                  className='cursor-pointer relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8'
                >
                  {payment.status === 'ACTIVE' && (
                    <div className='flex shrink-0 items-center '>
                      <div className='flex-none rounded-full bg-emerald-500/20 p-1'>
                        <div className='h-1.5 w-1.5 rounded-full bg-emerald-500' />
                      </div>
                    </div>
                  )}

                  <div>
                    <p className='text-base font-semibold leading-6 text-gray-900'>
                      startet am
                    </p>
                    <p className='text-sm text-gray-500'>
                      {format(new Date(payment.start_at), 'dd.MM.yyyy')}
                    </p>
                  </div>
                  <div className='mx-4'>
                    <p className='text-base font-semibold leading-6 text-gray-900'>
                      gültig bis
                    </p>
                    <p className='text-sm text-gray-500'>
                      {format(new Date(payment.expires_at), 'dd.MM.yyyy')}
                    </p>
                  </div>
                  <div className='min-w-0 flex-auto'>
                    <p className='text-base font-semibold leading-6 text-gray-900'>
                      Paket
                    </p>
                    <p className='text-sm text-gray-500'>Pro</p>
                  </div>
                  <div className='flex shrink-0 items-center gap-x-4'>
                    <div>
                      <p className='text-base font-semibold leading-6 text-gray-900'>
                        Preis
                      </p>
                      <p className='text-sm text-gray-500'>
                        {formatToCurrency((payment.price / 100) * 12)}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-none text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Customer
