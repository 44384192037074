import { useQuery } from 'react-query'
import {
  PlayIcon,
  PencilSquareIcon,
  HomeIcon,
  PlusIcon
} from '@heroicons/react/20/solid'

import { query } from '../api'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'

const pages = [
  { name: 'System', to: '/system', current: false },
  { name: 'Challenges', to: '#', current: true }
]

const Challenges = () => {
  const { data: challenges, isLoading } = useQuery({
    queryKey: ['challanges'],
    queryFn: () =>
      query({ query: 'getSystem', variables: { id: 'CHALLENGES' } }),
    select: (res) => Object.values(JSON.parse(res.data))
  })

  if (isLoading) {
    return <Loader loadingText={'Challenges werden geladen ...'} />
  }

  return (
    <div>
      <nav className='flex mb-8' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to={'/'} className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <svg
                  className='h-5 w-5 flex-shrink-0 text-gray-300'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                <Link
                  to={page.to}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <header className='mb-8'>
        <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
          Challenges
        </h1>
      </header>
      <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
        {challenges.map((c) => (
          <li
            key={c.exam_id}
            className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow'
          >
            <div className='flex w-full items-center justify-between space-x-6 p-6'>
              <div className='flex-1 truncate'>
                <div className='flex items-center space-x-3'>
                  <h3 className='truncate text-sm font-medium text-gray-900'>
                    {c.title.de}
                  </h3>
                  <span className='inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                    {c.category}
                  </span>
                  <div
                    className={`h-3 w-3 ${
                      c.active === true ? 'bg-green-500' : 'bg-orange-500'
                    } rounded-full`}
                  />
                </div>
                <p className='mt-1 truncate text-sm text-gray-500'>
                  {c.dimensionSynonym.de}
                </p>
              </div>
              <img
                className='h-10 w-10 flex-shrink-0 rounded-full bg-gray-300'
                src={c.card.image}
                alt=''
              />
            </div>
            <div>
              <div className='-mt-px flex divide-x divide-gray-200'>
                <div className='flex w-0 flex-1 hover:opacity-30'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`http://webapp.aivy.app/?challenge=${c.exam_id}`}
                    className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 cursor-pointer'
                  >
                    <PlayIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                    Spielen
                  </a>
                </div>
                <div className='-ml-px flex w-0 flex-1 hover:opacity-30'>
                  <Link className='relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 cursor-pointer'>
                    <PencilSquareIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                    Bearbeiten
                  </Link>
                </div>
              </div>
            </div>
          </li>
        ))}
        <li
          key={'new'}
          className=' rounded-lg bg-white shadow flex items-center justify-center '
        >
          <div className='text-center '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='mx-auto h-12 w-12 text-gray-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z'
              />
            </svg>

            <div className='mt-2'>
              <button
                type='button'
                className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                <PlusIcon
                  className='-ml-0.5 mr-1.5 h-5 w-5'
                  aria-hidden='true'
                />
                Neue Challenge
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Challenges
