import FadeLoader from 'react-spinners/FadeLoader'

const Loader = ({ loadingText }) => {
  return (
    <div className='w-full flex flex-col items-center justify-center py-8'>
      <FadeLoader
        color={'#4461D4'}
        loading={true}
        aria-label='Loading Spinner'
        data-testid='loader'
      />
      <span className='font-normal text-sm mt-2'>{loadingText}</span>
    </div>
  )
}

export default Loader
