import { useEffect } from 'react'
import { Amplify, I18n } from 'aws-amplify'

import {
  Authenticator,
  translations,
  useAuthenticator
} from '@aws-amplify/ui-react'
import { useNavigate, useLocation } from 'react-router'

import '@aws-amplify/ui-react/styles.css'
import AivyLogo from '../assets/images/logo.png'
import awsExports from '../aws-exports'

Amplify.configure(awsExports)

I18n.putVocabularies(translations)
I18n.setLanguage('de')

const Header = () => (
  <div className='flex flex-col py-6 items-center'>
    <img src={AivyLogo} alt='Logo' className='w-48 h-auto' />
  </div>
)

const Footer = () => (
  <div className='text-center p-4'>
    <span>&copy; All Rights Reserved</span>
  </div>
)

const SignIn = () => {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])

  return (
    <div className='flex w-full h-screen items-center justify-center bg-paleGrey'>
      <Authenticator hideSignUp components={{ Header, Footer }} />
    </div>
  )
}

export default SignIn
