import { Link, useParams, useNavigate } from 'react-router-dom'
import { HomeIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { RadioGroup, Switch } from '@headlessui/react'
import { useQuery } from 'react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CurrencyInput from 'react-currency-input-field'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import DatePicker from 'react-date-picker'

import { mutation, query } from '../api'
import Loader from '../components/Loader'
import { classNames } from '../helper'
import { PRODUCTS } from '../constants/products'
import { useNotificationContext } from '../context/notification-context'

const validationSchema = Yup.object().shape({
  products: Yup.array().required(),
  price: Yup.number().required('Price darf nicht leer sein'),
  seat_count: Yup.number()
    .positive('Anzahl der Teammitglieder darf nicht negativ sein')
    .required('Anzahl der Teammitglieder ist erforderlich'),
  career_count: Yup.number()
    .positive('Anzahl der Stellen darf nicht negativ sein')
    .required('Anzahl der Stellen ist erforderlich'),
  spaces_count: Yup.number()
    .positive('Anzahl der Testungen darf nicht negativ sein')
    .required('Anzahl der Testungen ist erforderlich')
})

const Payment = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { success } = useNotificationContext()

  const {
    isLoading: isLoadingPayment,
    data: payment,
    refetch: refetchPayment
  } = useQuery({
    queryKey: ['payment', id],
    queryFn: () => query({ query: 'getPayment', variables: { id } }),
    refetchOnWindowFocus: false
  })

  const { data: customer, isLoading: isLoadingCustomer } = useQuery({
    queryKey: ['customer', payment?.partner_id],
    queryFn: () =>
      query({ query: 'getPartner', variables: { id: payment?.partner_id } }),
    enabled: !!payment,
    refetchOnWindowFocus: false
  })

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      is_trial: payment?.is_trial || false,
      products: payment?.products || [],
      price: (payment?.price && payment.price / 100) || 0,
      seat_count: payment?.seat_count || 0,
      spaces_count: payment?.spaces_count || 0,
      career_count: payment?.career_count || 0,
      expires_at: payment?.expires_at
        ? new Date(payment.expires_at)
        : new Date()
    },
    onSubmit: (values) => {
      formik.setSubmitting(true)

      const input = {
        id: payment.id,
        products: values.products,
        price: parseFloat(values.price) * 100,
        career_count: values.career_count,
        seat_count: values.seat_count,
        // hirings_count: hires,
        is_trial: values.is_trial,
        spaces_count: values.spaces_count,
        expires_at: new Date(values.expires_at).toISOString()
      }

      mutation({
        mutation: 'updatePayment',
        input
      }).then(() => {
        refetchPayment()
        formik.setSubmitting(false)

        success('Zahlung erfolgreich aktualisiert')
      })
    }
  })

  const onClickProduct = (product) => {
    const active = formik.values.products.includes(product)

    if (active === false) {
      formik.setFieldValue('products', [...formik.values.products, product])
    } else {
      formik.setFieldValue('products', [
        ...formik.values.products.filter((p) => p !== product)
      ])
    }
  }

  const customerName = customer?.display_name || customer?.name

  const pages = [
    { name: 'Kunden', to: '/customers', current: false },
    {
      name: customerName,
      to: `/customer/${payment?.partner_id}`,
      current: false
    },
    { name: 'Zahlung', to: '#', current: true }
  ]

  if (isLoadingPayment || isLoadingCustomer) {
    return <Loader loadingText={'Zahlung wird geladen ...'} />
  }

  return (
    <div>
      <nav className='flex mb-8' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to={'/'} className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <svg
                  className='h-5 w-5 flex-shrink-0 text-gray-300'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                <Link
                  to={page.to}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <header className='mb-8'>
        <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
          {`Zahlung für ${customerName}`}
        </h1>
      </header>
      <div className='space-y-12 sm:space-y-16 border-b border-gray-900/10 pb-12'>
        <div>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Basisdaten
          </h2>
          <p className='mt-1 max-w-2xl text-sm leading-6 text-gray-600'>
            Hinterlege das Pricing, Anzahl Testungen oder Stellen u.v.m
          </p>
          <div className='mt-4 grid gap-8 grid-cols-1 grid-rows-6 sm:grid-cols-2 sm:grid-rows-3'>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Preis / Monat
              </label>
              <div className='mt-2'>
                <CurrencyInput
                  id='price'
                  suffix={'€'}
                  defaultValue={0}
                  name='price'
                  placeholder='Preis pro Monat'
                  className='mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-3'
                  value={formik.values.price}
                  onBlur={formik.handleBlur}
                  decimalsLimit={2}
                  onValueChange={(value, name) => {
                    formik.setFieldValue('price', value)
                  }}
                />
                {formik.errors.price ? (
                  <div className='text-sm text-red-600 mt-1'>
                    {formik.errors.price}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor='career_count'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Anzahl besetzbaren Stellen
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  min={0}
                  value={formik.values.career_count}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='career_count'
                  id='career_count'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3'
                  placeholder='Anzahl besetzbare Stellen ...'
                />
                {formik.errors.career_count ? (
                  <div className='text-sm text-red-600 mt-1'>
                    {formik.errors.career_count}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor='seat_count'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Seats (Accounts / Teammitglieder)
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  min={0}
                  value={formik.values.seat_count}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='seat_count'
                  id='seat_count'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3'
                  placeholder='Anzahl der Teammitglieder im Dashboard hinterlegen'
                />
                {formik.errors.seat_count ? (
                  <div className='text-sm text-red-600 mt-1'>
                    {formik.errors.seat_count}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor='spaces_count'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Anzahl der Testungen pro Jahr
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  min={0}
                  value={formik.values.spaces_count}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='spaces_count'
                  id='spaces_count'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3'
                  placeholder='Anzahl der Teammitglieder im Dashboard hinterlegen'
                />
                {formik.errors.spaces_count ? (
                  <div className='text-sm text-red-600 mt-1'>
                    {formik.errors.spaces_count}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor='expires_at'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Ablaufdatum (Abonnement)
              </label>
              <DatePicker
                className={'mt-2'}
                locale='de-DE'
                value={formik.values.expires_at}
                onChange={(value) => formik.setFieldValue('expires_at', value)}
              />
            </div>
            <div>
              <Switch.Group
                as='div'
                className='flex items-center justify-between'
              >
                <span className='flex flex-grow flex-col'>
                  <Switch.Label
                    as='span'
                    className='text-sm font-medium leading-6 text-gray-900'
                    passive
                  >
                    Trial
                  </Switch.Label>
                  <Switch.Description
                    as='span'
                    className='text-sm text-gray-500'
                  >
                    Gibt an, ob die aktuelle Zahlung ein Trial ist. Um Kunden zu
                    zahlenden Kunden zu konvertieren, bitte Trial hier
                    ausschalten
                  </Switch.Description>
                </span>
                <Switch
                  id='is_trial'
                  checked={formik.values.is_trial}
                  onChange={() =>
                    formik.setFieldValue('is_trial', !formik.values.is_trial)
                  }
                  className={classNames(
                    formik.values.is_trial ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden='true'
                    className={classNames(
                      formik.values.is_trial
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
        </div>
      </div>
      <div className='space-y-12 sm:space-y-16 border-b border-gray-900/10 pb-12 mt-12'>
        <div>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Produkte
          </h2>
          <p className='mt-1 max-w-2xl text-sm leading-6 text-gray-600'>
            Wähle folgende Produkte aus, die dem Kunden im Dashboard zur
            Verfügung stehen sollen
          </p>
          <RadioGroup value={formik.values.products} onChange={onClickProduct}>
            <div className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4'>
              {Object.entries(PRODUCTS).map(([key, value]) => (
                <RadioGroup.Option
                  key={key}
                  value={key}
                  className={classNames(
                    formik.values.products.includes(key)
                      ? 'border-indigo-600 ring-2 ring-indigo-600'
                      : 'border-gray-300',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                  )}
                >
                  {({ checked, active }) => (
                    <>
                      <span className='flex flex-1'>
                        <span className='flex flex-col'>
                          <RadioGroup.Label
                            as='span'
                            className='block text-sm font-medium text-gray-900'
                          >
                            {value.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as='span'
                            className='mt-1 flex items-center text-sm text-gray-500'
                          >
                            {value.description}
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? 'invisible' : '',
                          'h-5 w-5 text-indigo-600'
                        )}
                        aria-hidden='true'
                      />
                      <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-indigo-600' : 'border-transparent',
                          'pointer-events-none absolute -inset-px rounded-lg'
                        )}
                        aria-hidden='true'
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>

      <div className='mt-6 flex items-center justify-end gap-x-6'>
        <button
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
          onClick={() => navigate(-1)}
        >
          Abbrechen
        </button>
        <button
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
          className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            !formik.dirty && 'opacity-30'
          }`}
        >
          {formik.isSubmitting ? 'lädt ...' : 'Speichern'}
        </button>
      </div>
    </div>
  )
}

export default Payment
