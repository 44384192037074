export const INTEGRATION = `{
  id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  partner_id
  partner {
    id
    name
    display_name
    logo
    createdAt
    updatedAt
  }
  integration_settings {
    url
    email
    token
    username
    password
    assessment_status_reference
    assessment_test_reference
  }
  remote_service
  remote_service_name
  api_call_count
  createdAt
  updatedAt
}`
