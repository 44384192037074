export const PAYMENT = `{
  id
  partner_id
  authorized_read
  invoice_url
  products
  expires_at
  start_at
  price
  payment_service
  spaces_count
  unused_space_count
  career_count
  seat_count
  campaign_credits
  is_trial
  unused_campaign_credits
  hirings_count
  status
  payment_type
  createdAt
  updatedAt
  __typename
}`
