import { useQuery } from 'react-query'
import { query } from '../api'

import Loader from '../components/Loader'
import Table from '../components/Table'
import { getMonthlyBudgetById } from '../helper'
import { format } from 'date-fns'

const Leads = () => {
  const { data: leads, isLoading } = useQuery({
    queryKey: ['leads'],
    queryFn: () =>
      query({
        query: 'listForms',
        variables: {
          filter: {
            form_source: {
              eq: 'LEAD_QUALIFICATION_FUNNEL'
            }
          }
        }
      })
  })

  if (isLoading) {
    return <Loader loadingText={'Leads werden geladen ...'} />
  }

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id'
    },
    {
      id: 'data_one',
      header: 'E-MAIL-ADRESSE',
      accessorKey: 'data',
      cell: ({ cell }) => <p>{JSON.parse(cell.row.original.data).EMAIL}</p>
    },
    {
      id: 'data_two',
      header: 'TELEFONNUMMER',
      accessorKey: 'data',
      cell: ({ cell }) => <p>{JSON.parse(cell.row.original.data).PHONE}</p>
    },
    {
      id: 'data_three',
      header: 'MONATLICHES BUDGET',
      accessorKey: 'data',
      cell: ({ cell }) => (
        <p>
          {getMonthlyBudgetById(
            JSON.parse(cell.row.original.data).MONTHLY_BUDGET
          )}
        </p>
      )
    },
    {
      header: 'STATUS',
      accessorKey: 'status'
    },
    {
      header: 'ERSTELLT AM',
      accessorKey: 'createdAt',
      cell: (props) => (
        <span>{format(new Date(props.getValue()), 'dd.MM.yyyy')}</span>
      )
    },
    {
      header: 'ZULETZT GEÄNDERT',
      accessorKey: 'updatedAt',
      cell: (props) => (
        <span>{format(new Date(props.getValue()), 'dd.MM.yyyy')}</span>
      )
    }
  ]

  return (
    <div>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Leads
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
      </div>
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <Table data={leads || []} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Leads
