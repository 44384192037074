import { API, graphqlOperation } from 'aws-amplify'

import * as queries from './queries'
import * as mutations from './mutations'

export function query({ query, variables = {} }) {
  if (!query) throw new Error('Missing parameter')

  const promise = new Promise((resolve, reject) => {
    const possibleItems = []

    const request = (nextToken) => {
      API.graphql(
        graphqlOperation(queries[query], {
          limit: 10000, // can be overwritten by variables
          ...variables,
          nextToken
        })
      )
        .then(({ data }) => {
          if (!data[query]) {
            resolve(null)
          }

          if (data[query].items) {
            const items = data[query].items
            const nextToken = data[query].nextToken

            possibleItems.push(...items)

            if (nextToken) request(nextToken)
            else resolve(possibleItems)
          } else {
            resolve(data[query])
          }
        })
        .catch((error) => reject(error))
    }

    request()
  })

  return promise
}

export function mutation({ mutation, input }) {
  const promise = new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(mutations[mutation], { input }))
      .then(({ data }) => resolve(data[mutation]))
      .catch((error) => reject(error))
  })
  return promise
}
