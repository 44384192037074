import { PERSON } from './person'

export const USER = `{
  id
  owner
  time_in_app
  net_promoter_scores {
    score
    description
    product
    createdAt
    __typename
  }
  customer_dashboard_meta
  universal_app_meta
  language
  type
  role
  invited_from
  authorized_read
  authorized_update
  employee_at_partner
  person ${PERSON}
  opens_app
  opens_app_daily
  fingerprints {
    device_modelname
    device_os
    ip_adress
    browser_name
    __typename
  }
  avg_challenge_rating
  gender
  content_categories
  active_space_id
  delete_text
  privacy
  mail_stored
  phone_stored
  last_seen
  createdAt
  updatedAt
  lastUpdatedBy
  career_right_swipe_rate
  suggested_hero
  hero
  email_unsubscribed_topics
  gcm_unsubscribed_topics
  apns_unsubscribed_topics
  location {
    lat
    lon
    city
    __typename
  }
  push_token
  percentiles {
    BIG_FIVE_o_score
    BIG_FIVE_c_score
    BIG_FIVE_e_score
    BIG_FIVE_a_score
    BIG_FIVE_n_score
    BIG_FIVE_elevation
    RIASEC_IMAGES_elevation
    RIASEC_IMAGES_r_score
    RIASEC_IMAGES_i_score
    RIASEC_IMAGES_a_score
    RIASEC_IMAGES_s_score
    RIASEC_IMAGES_e_score
    RIASEC_IMAGES_c_score
    HEROS_JOURNEY_score_coopcomp
    HEROS_JOURNEY_score_orderchaos
    TRUST_score
    TROLLEY_score
    SPEEDACC_score
    FLYINGDUTCH_score
    RESPDECISION_score
    PATH_FINDER_score
    FLANKER_score
    EMOTIONS_GAME_score
    ANALOGICAL_REASONING_score
    TOWER_OF_LONDON_score
    DIGIT_SPAN_score
    NBACK_score
    FLANKER_avg_speed
    DELEGATION_score_risk
    DELEGATION_score_responsibility
    __typename
  }
  education_level
  work_experience
  remote
  __typename
}`
