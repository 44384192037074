import { useEffect, useRef, useState } from 'react'
import {
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import BeatLoader from 'react-spinners/BeatLoader'
import { JSONTree } from 'react-json-tree'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { formatDistance } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import { isNumber } from 'lodash'

import DataSearch from '../assets/images/data-search.png'
import { useFindAll } from '../hooks/use-find-all'
import { COGNITO_POOL_URL, DYNAMO_DB_URL, classNames, uuidv4 } from '../helper'

function removeNull(obj) {
  if (Array.isArray(obj)) {
    // If it's an array, filter out null values from each element
    return obj.map(removeNull).filter((x) => x !== null)
  } else if (typeof obj === 'object' && obj !== null) {
    // If it's an object, recursively remove null values from its properties
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newValue = removeNull(value)
      if (newValue !== null) {
        acc[key] = newValue
      }
      return acc
    }, {})
  } else {
    // Return null values as is
    return obj
  }
}

const SpaceDetailsCards = ({ space }) => {
  const { progress, context, expiresAt, owner } = space

  const stats = [
    {
      name: 'PROGRESS',
      available: true,
      stat: isNumber(progress) ? progress : 'n/a'
    },
    { name: 'CONTEXT', available: true, stat: context },
    {
      name: 'EXPIRES_AT',
      available: !!expiresAt,
      stat: formatDistance(new Date(expiresAt), new Date(), {
        locale: { de, en }['de'], // eslint-disable-line
        addSuffix: true
      })
    },
    {
      name: 'COGNITO_URL',
      available: !!owner,
      stat: (
        <a
          href={COGNITO_POOL_URL({ username: owner })}
          target='_blank'
          rel='noreferrer'
          className='text-indigo-700 hover:text-indigo-600'
        >
          Link zu Cognito
        </a>
      )
    }
  ]

  return (
    <dl className='grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-4'>
      {stats
        .filter(({ available }) => available)
        .map((item) => (
          <div
            key={item.name}
            className='overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6'
          >
            <dt className='truncate text-sm font-medium text-gray-500'>
              {item.name}
            </dt>
            <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
              {item.stat}
            </dd>
          </div>
        ))}
    </dl>
  )
}

const Divider = () => (
  <div className='px-16 py-8'>
    <div className='h-px bg-gray-200' />
  </div>
)

const Inspect = () => {
  const { options, data, clearAll } = useFindAll()

  const inputRef = useRef(null)
  const scrollToRef = useRef(null)

  const [selectedField, setSelectedField] = useState(options[0].id)
  const [displayDataDetails, setDisplayDataDetails] = useState(null)
  const [inputKey, setInputKey] = useState(uuidv4())

  useEffect(() => {
    inputRef.current.focus()
  }, [inputKey])

  const selectedFieldOption = options.find(({ id }) => id === selectedField)
  const isFetching = Object.keys(data).some((key) => data[key] === 'fetching')
  const some = Object.keys(data).some((key) => !!data[key])

  const scrollToData = () => {
    setTimeout(
      () =>
        scrollToRef.current.scrollIntoView({
          behavior: 'smooth'
        }),
      0.6 * 1000 // wait some time for data
    )
  }

  const reset = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    clearAll()
    setInputKey(uuidv4())
    setDisplayDataDetails(null)
  }

  return (
    <>
      <div className='max-w-xl mx-auto flex flex-col'>
        <img className='mt-8 block h-64 w-auto mx-auto' src={DataSearch} />
        <div ref={scrollToRef}></div>
        <div className='mt-8 p-4 bg-white rounded-full'>
          <div className='flex rounded-md items-center'>
            <div style={{ width: 148 }} className='shrink-0'>
              <select
                id='find_all'
                name='find_all'
                value={selectedField}
                onChange={(event) => setSelectedField(event.target.value)}
                className='relative block w-full border-0 bg-transparent py-2 text-gray-900 focus:z-10 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-pointer disabled:cursor-default'
                disabled={some || isFetching}
              >
                {options.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full mx-2'>
              <form
                onSubmit={(event) => {
                  event.preventDefault()
                  selectedFieldOption.onSubmit()

                  scrollToData()
                }}
              >
                <input
                  ref={inputRef}
                  key={inputKey}
                  type='text'
                  id={selectedFieldOption.field}
                  name={selectedFieldOption.field}
                  onChange={selectedFieldOption.onChange}
                  className='relative block w-full border-0 bg-white rounded-lg py-2 text-gray-900 placeholder:text-gray-400 focus:z-10 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder={selectedFieldOption.placeholder}
                  disabled={some || isFetching}
                />
              </form>
            </div>
            <div
              onClick={
                some
                  ? reset
                  : () => {
                      selectedFieldOption.onSubmit()
                      scrollToData()
                    }
              }
              disabled={isFetching}
              className='shrink-0 w-10 h-10 flex justify-center items-center rounded-full bg-gray-50 cursor-pointer group hover:bg-gray-100'
            >
              {some && !isFetching ? (
                <XMarkIcon className='w-5 h-5 text-gray-700 group-hover:text-gray-900' />
              ) : (
                <MagnifyingGlassIcon className='w-5 h-5 text-gray-700 group-hover:text-gray-900' />
              )}
            </div>
          </div>
        </div>
      </div>
      {data.space && data.space !== 'fetching' && data.space !== 'error' && (
        <>
          <Divider />
          <SpaceDetailsCards space={data.space} />
        </>
      )}
      {some && (
        <>
          <Divider />
          <div
            style={{ height: 512 }}
            className='relative flex mx-auto mb-32 rounded-lg max-w-7xl bg-white divide-gray-200 divide-x overflow-hidden'
          >
            <div
              className={classNames(
                'divide-gray-200 divide-y overflow-scroll',
                displayDataDetails ? 'w-1/2' : 'w-full'
              )}
            >
              {[
                { data: data.space, title: 'Space' },
                { data: data.spaces, title: 'Spaces' },
                { data: data.career, title: 'Career' },
                { data: data.partner, title: 'Partner' },
                { data: data.user, title: 'User' },
                { data: data.answers, title: 'Answers' },
                { data: data.person, title: 'Person' },
                { data: data.cognitoUser, title: 'CognitoUser' },
                { data: data.payments, title: 'Payments' },
                { data: data.employees, title: 'Employees' },
                { data: data.careers, title: 'Careers' }
              ]
                .filter(
                  ({ data }) =>
                    !!data && data !== 'fetching' && data !== 'error'
                )
                .map(({ data, title }, index) => (
                  <div
                    key={index}
                    onClick={() => setDisplayDataDetails(data)}
                    className='px-6 py-4 flex justify-between group cursor-pointer hover:bg-gray-50'
                  >
                    <div className='text-sm text-gray-900'>
                      <span className='font-semibold'>{title}</span>
                      {data.id && (
                        <>
                          <span className='mx-1 text-gray-700 group-hover:text-gray-900'>
                            |
                          </span>
                          <span className='text-gray-700 group-hover:text-gray-900'>
                            {data.id}
                          </span>
                        </>
                      )}
                      {Array.isArray(data) && (
                        <>
                          <span className='mx-1 text-gray-700 group-hover:text-gray-900'>
                            |
                          </span>
                          <span className='text-gray-700 group-hover:text-gray-900'>
                            {data.length} items
                          </span>
                        </>
                      )}
                    </div>
                    <ChevronRightIcon
                      className='h-5 w-5 flex-none text-gray-700 group-hover:text-gray-900'
                      aria-hidden='true'
                    />
                  </div>
                ))}
            </div>
            {!!displayDataDetails && (
              <div className='px-6 py-4 overflow-scroll w-1/2'>
                <div className='relative'>
                  <JSONTree data={removeNull(displayDataDetails)} />
                  {!!displayDataDetails.__typename && (
                    <div
                      onClick={() =>
                        window.open(
                          DYNAMO_DB_URL({
                            __typename: displayDataDetails.__typename,
                            id: displayDataDetails.id
                          }),
                          '_blank'
                        )
                      }
                      className='z-10 absolute top-2 right-2 shrink-0 w-10 h-10 flex justify-center items-center rounded-full bg-gray-50 cursor-pointer group hover:bg-gray-100'
                    >
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-700 group-hover:text-gray-900' />
                    </div>
                  )}
                </div>
              </div>
            )}
            {isFetching && (
              <div className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-white'>
                <BeatLoader color='#374151' size={16} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Inspect
