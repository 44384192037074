import './App.css'
import { Route, Routes } from 'react-router-dom'
import SignIn from './pages/signin'
import { RequireAuth } from './components/RequireAuth'
import Overview from './pages/overview'
import Layout from './components/layout'
import Customers from './pages/customers'
import Talents from './pages/talents'
import System from './pages/system'
import Challenges from './pages/challenges'
import Customer from './pages/customer'
import Payment from './pages/payment'
import Leads from './pages/leads'
import Inspect from './pages/inspect'
import Integrations from './pages/integrations'

function App() {
  return (
    <Routes>
      <Route path='/login' element={<SignIn />} />
      <Route
        path='/'
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<Overview />} />
        <Route path='customers' element={<Customers />} />
        <Route path='leads' element={<Leads />} />
        <Route path='customer/:id' element={<Customer />} />
        <Route path='customer/payment/:id' element={<Payment />} />
        <Route path='talents' element={<Talents />} />
        <Route path='system' element={<System />} />
        <Route path='system/challenges' element={<Challenges />} />
        <Route path='inspect' element={<Inspect />} />
        <Route path='integrations' element={<Integrations />} />
      </Route>
      <Route path='*' element={<p>Page not found</p>} />
    </Routes>
  )
}

export default App
