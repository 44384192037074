import { useQuery } from 'react-query'
import { format } from 'date-fns'

import Loader from '../components/Loader'
import Table from '../components/Table'
import { query } from '../api'

function truncateText(text, maxLength) {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
}

const Integrations = () => {
  const { data: integrations, status: iStatus } = useQuery({
    queryKey: ['integrations'],
    queryFn: () => query({ query: 'listIntegrations' })
  })

  const { status: ifcStatus, data: integrationsFromControl } = useQuery({
    queryKey: ['integrations_from_control'],
    queryFn: () =>
      query({
        query: 'integrationControl',
        variables: {
          action: 'listIntegrations'
        }
      }),
    select: (response) => {
      const integrationsResult = {}
      JSON.parse(response).listIntegrations.res.forEach(
        (i) =>
          (integrationsResult[i.remote_service_name || i.remote_service] = {
            ...i
          })
      )

      return integrationsResult
    }
  })

  if ([iStatus, ifcStatus].includes('loading')) {
    return <Loader loadingText={'Integrationen werden geladen ...'} />
  }

  const data = integrations.map((integration) => {
    const { remote_service_name, remote_service, partner } = integration
    const { logo, logo_landscape } =
      integrationsFromControl[remote_service_name || remote_service]

    const { id: partner_id, name, display_name } = partner || {}
    const partner_name = truncateText(
      [name, display_name].filter((i) => i).join(' / ') || 'n/a',
      32
    )

    return {
      ...integration,
      partner_id: partner_id || 'n/a',
      partner_name,
      logo,
      logo_landscape,
      isActive: true
    }
  })

  const columns = [
    {
      header: '',
      accessorKey: 'logo',
      cell: ({ getValue }) => (
        <div className='ml-4 mr-2 w-12 h-12 bg-white rounded-full p-3'>
          <img src={getValue()} className='w-6 h-6' />
        </div>
      )
    },
    {
      header: 'NAME',
      accessorKey: 'remote_service',
      accessorFn: (row) => row.remote_service_name || row.remote_service,
      cell: ({ getValue }) => (
        <span className='text-sm font-medium text-gray-900'>{getValue()}</span>
      )
    },
    {
      header: 'ID',
      accessorKey: 'id',
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-700'>{getValue()}</span>
      )
    },
    {
      header: 'PARTNER',
      accessorKey: 'partner_name',
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-700'>{getValue()}</span>
      )
    },
    {
      header: 'PARTNER_ID',
      accessorKey: 'partner_id',
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-700'>{getValue()}</span>
      )
    },
    {
      header: 'ERSTELLT AM',
      accessorKey: 'createdAt',
      cell: ({ getValue }) => (
        <span className='block text-sm text-end text-gray-700'>
          {format(new Date(getValue()), 'dd.MM.yyyy')}
        </span>
      )
    },
    {
      header: 'ZULETZT GEÄNDERT',
      accessorKey: 'updatedAt',
      cell: ({ getValue }) => (
        <span className='block text-sm text-end text-gray-700'>
          {format(new Date(getValue()), 'dd.MM.yyyy')}
        </span>
      )
    }
  ]

  return (
    <div>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Integrationen
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            Ein Überblick über die Integrationen unserer Partner.
          </p>
        </div>
      </div>
      <div className='my-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <Table data={data} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Integrations
