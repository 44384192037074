import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline'

import { query } from '../api'
import Table from '../components/Table'
import Loader from '../components/Loader'
import { useState } from 'react'
import Button from '../components/Button'
import CreateCompanyModal from '../components/CreateCompanyModal'

const Customers = () => {
  const [open, setOpen] = useState(false)

  const { data: customers, isLoading } = useQuery({
    queryKey: ['customers'],
    queryFn: () => query({ query: 'listPartners' })
  })

  const navigate = useNavigate()

  const handleNavigate = (cell) => {
    const { id } = cell.row.original

    navigate(`/customer/${id}`, { replace: true })
  }

  const stats = [
    { name: 'Anzahl Kunden', stat: customers?.length },
    { name: 'Anzahl zahlender Kunden', stat: 'TBD' },
    { name: 'Anzahl Kunden im Trial', stat: 'TBD' }
  ]

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id'
    },
    {
      header: 'NAME',
      accessorKey: 'name'
    },
    {
      header: 'DISPLAY_NAME',
      accessorKey: 'display_name'
    },
    {
      header: 'ERSTELLT AM',
      accessorKey: 'createdAt',
      cell: (props) => (
        <span>{format(new Date(props.getValue()), 'dd.MM.yyyy')}</span>
      )
    },
    {
      header: 'ZULETZT GEÄNDERT',
      accessorKey: 'updatedAt',
      cell: (props) => (
        <span>{format(new Date(props.getValue()), 'dd.MM.yyyy')}</span>
      )
    },
    {
      accessorKey: 'view',
      enableSorting: false,
      header: '',
      cell: () => (
        <ArrowSmallRightIcon className='ml-auto w-5 h-5 text-gray-500 group-hover:text-gray-900' />
      )
    }
  ].map((i) => ({ ...i, onClick: (cell) => handleNavigate(cell) }))

  if (isLoading) {
    return <Loader loadingText={'Kunden werden geladen ...'} />
  }

  return (
    <div>
      <div>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Kunden
            </h1>
            <p className='mt-2 text-sm text-gray-700'>
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button.PrimaryXL
              text='Neuen Kunden hinzufügen'
              onClick={() => setOpen(!open)}
            />
          </div>
        </div>
        <div className='mt-8'>
          <dl className=' grid grid-cols-1 gap-5 sm:grid-cols-3'>
            {stats.map((item) => (
              <div
                key={item.name}
                className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'
              >
                <dt className='truncate text-sm font-medium text-gray-500'>
                  {item.name}
                </dt>
                <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                  {item.stat}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <Table data={customers || []} columns={columns} />
            </div>
          </div>
        </div>
      </div>
      <CreateCompanyModal open={open} setOpen={setOpen} />
    </div>
  )
}

export default Customers
