export const BUILD_ENV = process.env.REACT_APP_USER_BRANCH || 'develop'

const TABLES = {
  develop: 'ou67y5wj6jgsjf5k76keyupg2a-develop',
  staging: 'jbuigv6qkrhwtiy5nimzv7wa44-staging',
  production: 'h2zruer6rnecpmwc477f524tey-production'
}

export const DYNAMO_DB_URL = ({ __typename, id }) => {
  return `https://eu-central-1.console.aws.amazon.com/dynamodbv2/home?region=eu-central-1#edit-item?table=${__typename}-${TABLES[BUILD_ENV]}&itemMode=2&pk=${id}&sk&route=ROUTE_ITEM_EXPLORER`
}

const COGNITO_POOLS = {
  develop: 'eu-central-1_Z5mMvG0dK',
  staging: 'eu-central-1_N6oOFcPGC',
  production: 'eu-central-1_YLHI2OQpd'
}

export const COGNITO_POOL_URL = ({ username }) => {
  return `https://eu-central-1.console.aws.amazon.com/cognito/v2/idp/user-pools/${COGNITO_POOLS[BUILD_ENV]}/users/details/${username}?region=eu-central-1`
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const formatToCurrency = (number) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  }).format(number)

export function checkIfAccountIsAdmin(cognitoUser) {
  return cognitoUser?.signInUserSession?.accessToken?.payload[
    'cognito:groups'
  ]?.includes('__admin')
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8 // eslint-disable-line

    return v.toString(16)
  })
}

export const getMonthlyBudgetById = (id) => {
  switch (id) {
    case 1:
      return '0-500 €'
    case 2:
      return '500-1.000 €'
    case 3:
      return '1.000-1.500 €'
    case 4:
      return '1.500-2.500 €'
    case 5:
      return '2.500-5.000 €'
    case 6:
      return '5.000 € +'
    default:
      return '-'
  }
}
