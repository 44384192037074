import { ANSWER } from './schema/answer'
import { CAREER } from './schema/career'
import { FORM } from './schema/form'
import { INTEGRATION } from './schema/integration'
import { PARTNER } from './schema/partner'
import { PAYMENT } from './schema/payment'
import { PERSON } from './schema/person'
import { SPACE } from './schema/space'
import { SYSTEM } from './schema/system'
import { USER } from './schema/user'

export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items ${PARTNER}
      nextToken
    }
  }
`

export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) ${SYSTEM}
  }
`

export const partnerControl = /* GraphQL */ `
  query PartnerControl(
    $action: String!
    $partner_id: ID
    $formID: ID
    $email: String
    $language: String
  ) {
    partnerControl(
      action: $action
      partner_id: $partner_id
      formID: $formID
      email: $email
      language: $language
    )
  }
`

export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) ${PARTNER}
  }
`

export const paymentByPartnerIdByCreatedAt = /* GraphQL */ `
  query PaymentByPartnerIdByCreatedAt(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByPartnerIdByCreatedAt(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${PAYMENT}
      nextToken
    }
  }
`

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) ${PAYMENT}
  }
`

export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items ${FORM}
      nextToken
    }
  }
`

export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) ${SPACE}
  }
`

export const answersByOwner = /* GraphQL */ `
  query AnswersByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${ANSWER}
      nextToken
    }
  }
`

export const spacesByOwner = /* GraphQL */ `
  query SpacesByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${SPACE}
      nextToken
    }
  }
`

export const careerByPartnerByDate = /* GraphQL */ `
  query CareerByPartnerByDate(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerByPartnerByDate(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${CAREER}
      nextToken
      __typename
    }
  }
`

export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) ${CAREER}
  }
`

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) ${USER}
  }
`

export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) ${PERSON}
  }
`

export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items ${INTEGRATION}
      nextToken
      __typename
    }
  }
`

export const integrationControl = /* GraphQL */ `
  query IntegrationControl(
    $action: String!
    $user_id: ID
    $space_id: ID
    $partner_id: ID
  ) {
    integrationControl(
      action: $action
      user_id: $user_id
      space_id: $space_id
      partner_id: $partner_id
    )
  }
`

export const spacesByPartnerIdByUpdatedAt = /* GraphQL */ `
  query SpacesByPartnerIdByUpdatedAt(
    $partner_id: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByPartnerIdByUpdatedAt(
      partner_id: $partner_id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${SPACE}
      nextToken
    }
  }
`
