import React from 'react'
import ReactDOM from 'react-dom/client'
import { Authenticator } from '@aws-amplify/ui-react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { NotificationContextProvider } from './context/notification-context'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <NotificationContextProvider>
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <BrowserRouter>
            <App />
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          </BrowserRouter>
        </Authenticator.Provider>
      </QueryClientProvider>
    </NotificationContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
